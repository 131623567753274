<template>
  <div class="nobody-dec">
    <div class="section1">
      <img src="../../assets/img/product3/section_img1.png" alt="">
    </div>
    <div class="section2">
      <h1>全程自动化完成称重过程，可根据客户实际情况二次定制化开发</h1>
      <div class="data2">
        <div class="item" v-for="(item, idx) in dataArr2" :key="idx">
          <div class="img-wrapper"><img :src="item.img" alt="" /></div>
          <h2>{{ item.title }}</h2>
          <h3>{{item.des}}</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "magdec",
  data() {
    return {
      dataArr2: [
        {
          img: require("@/assets/img/product3/img1.png"),
          title: '智能监控，无人值守',
          des:
            "所有设备远程集中控制，生产稳定，设备故障率低，无人值守减少人员，提高效率，平均减少人工5-10人，年节约人工成本50万以上"
        },
        {
          img: require("@/assets/img/product3/img2.png"),
          title: '磅单可视化，远程控制',
          des:
            "称重数据自动采集，自动处理，自动控制，从而能够实现人员在远程对数据的监控和统计"
        },
        {
          img: require("@/assets/img/product3/img3.png"),
          title: "最优化控制，提高产量",
          des:
            "设备智能化运行，货车磅单管理整体性最优"
        },
        {
          img: require("@/assets/img/product3/img4.png"),
          title: "精细化管理，实现节能降耗",
          des:
            "流程化，规范化，移动信息化，管理更精细，大数据实时分析，防微杜渐"
        }
      ]
    };
  }
};
</script>
<style lang="scss" scoped>
.nobody-dec {
  .section1 {
    background: #F5FBFF;
    background-position: center;
    height: 1250px;
    width: 100%;
    text-align: center;
    img {
      margin-top: 86px;
    }
  }

  .section2 {
    background: url(~@/assets/img/product3/section_bg2.png);
    background-position: center;
    height: 765px;
    width: 100%;
    margin: 0 auto;
    h1 {
      text-align: center;
      height: 29px;
      font-size: 30px;
      font-family: Heiti SC;
      font-weight: bold;
      color: #666666;
      line-height: 26px;
      padding-top: 74px;
      padding-bottom: 86px;
    }
    .data2 {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      .item {
        width: 266px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color:#EAF4FA;
        .img-wrapper {
          width: 266px;
          height: 222px;
          background: #ecf7ff;
          border-radius: 10px;
          text-align: center;
          overflow: hidden;
        }
        h2 {
          height: 21px;
          font-size: 20px;
          font-family: Lantinghei SC;
          font-weight: 600;
          color: #3E3E3E;
          margin-top: 50px;
          margin-bottom: 20px;
        }
        h3 {
          width: 244px;
          font-size: 18px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #666666;
          padding: 0 10px 60px 10px;
        }
      }
    }
  }

}
</style>
